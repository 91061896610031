import getClient from './client'

export default async function fetchYahooAdsNew(
  siteID,
  keyword,
  maxCount,
  type,
  market,
  source,
) {
  if (keyword === ``) return []
  if (siteID === undefined) return []

  const { data } = await getClient().get(
    `adpartners/${siteID}/fetchYahooAdsNew`,
    {
      params: {
        s: encodeURIComponent(keyword),
        n: maxCount,
        t: type,
        mkt: market,
        src: source,
      },
    },
  )

  if (!data || !data.data) {
    return {
      ads: [],
      status: `error`,
      message: `Error fetching ads`,
      yahooUrl: ``,
      yahooResponse: ``,
      taScore: ``,
    }
  }

  const originalData = data.data
  const newData = {
    ads: originalData.ads.map((item, index) => ({
      id: index,
      url: item.url.replace(/\\/g, ``),
      title: item.title.replace(/\\/g, ``),
      description: item.description.replace(/\\/g, ``),
      clickUrl: item.click_url.replace(/\\/g, ``),
      related: item.related,
      impressionId: item.impression_id,
      appNs: item.appNs,
      k: item.k,
      metadata: item.metadata,
    })),
    status: originalData.status,
    message: originalData.message,
    yahooUrl: originalData.yahoo_url,
    yahooResponse: originalData.yahoo_response,
    taScore: originalData.trueaudience_score,
  }

  return newData
}
